import React from "react"

import Layout from "../../components/flayout/index"
import SignupSuccess from "../../components/signupSuccess";

const Page = ({location}) => (
  <Layout seoFields={seoFields} showAnnouncement={false} loadTimesThankYouPixel={true} loadAdgebraThankYouPixel={true}>
    <SignupSuccess isInvestor={false} location={location}/>
  </Layout>
)

const seoFields = {
  description: "Download the MProfit mobile app on your Android or iOS device or login at https://cloud.mprofit.in",
  // lang,
  // meta,
  // keywords,
  title: "Sign-up Success" 
}

export default Page
